<template>
  <div class="header">
    <div>Camion</div>
    <div>Charge</div>
    <div>KM parcourus</div>
    <div>KM planifiés</div>
    <div>KM disponibles</div>
    <div>Dernier entretien</div>
  </div>
  <div v-for="(item, index) in data" :key="index" class="data-row">
    <div>
      <i class="fa fa-truck" :style="{ color: colors[item.truck_id], fontSize: '22px' }"/>
      <div>{{ item.truck_id }}</div>
    </div>
    <div>{{ item.charge }} %</div>
    <div>{{ formatNum(item.km_done) }} km</div>
    <div>{{ formatNum(item.planned_km) }} km</div>
    <div>{{ formatNum(item.available_km) }} km</div>
    <div>n.a</div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { getPlanificationData } from '@/commons/lib/apiClient.js'
import useProjectStore from '../../stores/project-store'
const projectStore = useProjectStore()
const { currentYear } = storeToRefs(projectStore)
const colors = { jak1: '#325F91', jak2: '#6E3787' }
const data = ref(null)
onMounted(async () => {
  const params = { assigned_km: 9000, captation_start: '-03-21', captation_end: '-12-01' }
  data.value = await getPlanificationData('get_trucks_charge', currentYear.value, params)
})
const formatNum = num => new Intl.NumberFormat('fr').format(num)
</script>
<style lang="scss" scoped>

.header {
  background-color: #f2f2f2;
  margin-bottom: 8px;
}

.header div, .data-row div {
  width: 20%;
}

.header,
.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

</style>
