import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import useFitBoundsToFeature from '@/commons/composables/useFitBoundsToFeature'
import useProjectStore from '@/stores/project-store'
export default function (mapRef, leftPanelSelector) {
  const { featureRef } = useFitBoundsToFeature(mapRef, leftPanelSelector)
  const projectStore = useProjectStore()
  const { selectedProject, features } = storeToRefs(projectStore)
  watch(selectedProject, newValue => {
    if (!newValue) return
    const selectedFeature = features.value.find(f => f.id === newValue)
    featureRef.value = selectedFeature
  })
}
