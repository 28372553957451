<template>
  <main>
    <google-sign-in-modal />
    <div style="position: fixed; top: 0; right: 0; padding: 8px; z-index: 1000;">
      <year-select v-model="currentYear" :start-year="2024" />
    </div>
    <left-panel-layout :key="currentYear" v-if="isAppReady" v-model="currentThematic" :items="thematics" :isLeftPanelOpen="true" :class="{ big: currentThematic.id === 'stream-thematic'}">
      <template #map>
        <map-component />
      </template>
      <template #tab="item">
        <!-- The i tag implements a font-awesome icon for each thematic. The img tag implements a specific icon, provided by us, for each thematic. Only one of this tag should be use in the same time -->
        <i :class="['app-toolbar__item__content', 'fa', item.faicon]" />
        <!-- <img :src="`${item.icon}.png`" :class="['app-toolbar__item__content', 'app-toolbar__item__content__icon']" /> -->
      </template>
      <template #content>
        <left-panel-content />
      </template>
    </left-panel-layout>
  </main>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import yearSelect from '../components/year-select.vue'
import { LeftPanelLayout } from '@jakarto3d/jakui'
import MapComponent from '@/components/map-component.vue'
import leftPanelContent from '@/components/left-panel-content.vue'
import useThematicsStore from '@/stores/thematics'
import { storeToRefs } from 'pinia'
import GoogleSignInModal from '@/commons/auth/GoogleSignInModal.vue'
import useProjectStore from '@/stores/project-store'

const thematicsStore = useThematicsStore()
const { thematics } = thematicsStore
const { currentThematic } = storeToRefs(thematicsStore)
const projectStore = useProjectStore()
const { populateData: populateProjectData } = projectStore
const { currentYear } = storeToRefs(projectStore)
const isAppReady = ref(false)

onMounted(async () => {
  await populateProjectData()
  isAppReady.value = true
})
watch(currentYear, async () => {
  await populateProjectData()
})

</script>
<style lang="scss" scoped>
:deep(.app-toolbar__item__input) {
  &:checked ~ .app-toolbar__item__content {
    color: var(--white);
  }

  &:checked ~ .app-toolbar__item__content__icon {
    filter: drop-shadow(0px 0px 2px var(--white)) drop-shadow(0px 0px 1px var(--white));
  }
}

:deep(.app-toolbar__item__content) {
  position: absolute;

  color: var(--black);

  transition: 125ms ease-in-out;
}

:deep(.left-panel) {
  left: max(-33vw, -400px);
  max-width: 33vw;
  background-color: #FBFBFB;
}
.big {
  :deep(.left-panel) {
    left: -66vw;
    width: 66vw;
    max-width: 66vw;
  }
}
</style>
