import axios from 'axios'
import { getToken } from '@/commons/auth/firebase-auth'

function buildHeaders (token) {
  return {
    Authorization: 'Bearer ' + token,
    'content-type': 'application/json'
  }
}

export function getDataFromFunction (sqlFunction, params, headers) {
  return axios.post(
    `${import.meta.env.VITE_BACKEND_URL}/applyFunctions`,
    {
      requests: [
        {
          sqlFunction,
          params
        }
      ]
    },
    { headers }
  )
}
function getStaticResponse (sqlFunction, year) {
  return axios.get(
    `/historical-data/${year}/${sqlFunction}.json`
  )
}

export async function getPlanificationData (sqlFunction, year, params) {
  const isYearEqualToCurrent = year === new Date().getFullYear()
  const token = await getToken()
  if (!import.meta.env.VITE_BACKEND_URL) {
    throw new Error('missing env var : VITE_BACKEND_URL')
  }

  const headers = token ? buildHeaders(token) : undefined
  const shouldUseStaticData = !isYearEqualToCurrent
  const response =
    shouldUseStaticData
      ? await getStaticResponse(sqlFunction, year)
      : await getDataFromFunction(sqlFunction, { year }, headers)
  const responseData = response.data
  return responseData.data[0].result
}

export async function getCityData (sqlFunction, params) {
  const token = await getToken()
  if (!import.meta.env.VITE_BACKEND_URL) {
    throw new Error('missing env var : VITE_BACKEND_URL')
  }

  const headers = token ? buildHeaders(token) : undefined
  const response = await getDataFromFunction(sqlFunction, params, headers)
  const responseData = response.data
  return responseData.data[0].result
}
