<template>
  <select id="yearSelector" v-model="modelValue">
    <option v-for="year in years" :key="year" :value="year">
      {{ year }}
    </option>
  </select>
</template>

<script setup>
import { computed } from 'vue'

// Define model for v-model
const modelValue = defineModel({
  type: Number,
  required: true,
  default: new Date().getFullYear()
})

// Props
const props = defineProps({
  startYear: {
    type: Number,
    required: true,
    validator: value => value <= new Date().getFullYear()
  }
})

// Reactive Variables
const currentYear = new Date().getFullYear()

// Computed Values
const years = computed(() => {
  const yearRange = []
  for (let year = props.startYear; year <= currentYear; year++) {
    yearRange.push(year)
  }
  return yearRange
})
</script>

<style scoped>
label {
  font-weight: bold;
  margin-right: 8px;
}

select {
  padding: 4px;
  font-size: 14px;
}

p {
  margin-top: 10px;
  font-style: italic;
}
</style>
