import { ref, watch } from 'vue'
import bbox from '@turf/bbox'
export default function (mapRef, leftPanelSelector) {
  const featureRef = ref(null)
  watch(featureRef, async feature => {
    if (!feature) return
    const leftPanelWidth = leftPanelSelector ? document.querySelector('.left-panel').offsetWidth : 0
    const defaultPadding = 400
    const leftPadding = defaultPadding + leftPanelWidth
    const padding = { left: leftPadding, top: defaultPadding, right: defaultPadding, bottom: defaultPadding }
    mapRef.value.map.fitBounds(bbox(feature), { padding })
  })
  return { featureRef }
}
