import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import useCityStore from '@/stores/city-store'
import useFitBoundsToFeature from '@/commons/composables/useFitBoundsToFeature'
export default function (mapRef, leftPanelSelector) {
  const { featureRef } = useFitBoundsToFeature(mapRef, leftPanelSelector)
  const cityStore = useCityStore()
  const { getGeom } = cityStore
  const { selected } = storeToRefs(cityStore)
  watch(selected, async newValue => {
    if (!newValue) return
    const selectedFeature = await getGeom(newValue)
    featureRef.value = selectedFeature
  })
}
