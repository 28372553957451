import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { getPlanificationData } from '@/commons/lib/apiClient.js'

async function getData (year) {
  const response = await getPlanificationData('get_planification_projects', year)
  return response.features
}

const useProjectStore = defineStore('project', () => {
  const currentYear = ref(new Date().getFullYear())
  const features = ref([])
  const visibleProjects = ref([])
  const selectedProject = ref(null)
  const populateData = async () => {
    features.value = await getData(currentYear.value)
    visibleProjects.value = features.value.map(f => f.id)
  }
  const projectsLayers = computed(() => {
    return features.value.filter(f => !f.properties.project_name.includes('Maintenance')).map(
      f => ({
        id: f.id,
        label: f.properties.project_name || 'no name'
      })
    )
  })
  const visibleFeatures = computed(() => {
    return features.value.filter(feature => visibleProjects.value.includes(feature.id))
  })
  const selectProjectByName = (projectName) => {
    selectedProject.value = features.value.find(f => f.properties.project_name === projectName).id
  }
  const unselectAllProjects = () => {
    visibleProjects.value = []
  }
  const selectAllProjects = () => {
    visibleProjects.value = features.value.map(f => f.id)
  }
  const areAllSelected = computed(() => {
    return visibleProjects.value.length === features.value.length
  })
  return {
    currentYear,
    features,
    populateData,
    projectsLayers,
    visibleProjects,
    visibleFeatures,
    selectedProject,
    selectProjectByName,
    areAllSelected,
    selectAllProjects,
    unselectAllProjects
  }
})

export default useProjectStore
